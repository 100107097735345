@font-face {
  font-family: "Fredoka-One";
  src: url(../../assets/fonts/FredokaOne-Regular.otf),
    url(../../assets/fonts/FredokaOne-Regular.ttf);
}
.gobjira-search {
  width: 100%;
  background: url("../../assets/images/bg.jpeg") no-repeat;
  flex-flow: column;
  position: relative;
}
.godjira-Logo {
  background: url("../../assets/images/projectgodjira.png") no-repeat;
  width: 435px;
  height: 60px;
  text-indent: -999999rem;
  margin: 0px auto;
  margin-bottom: 20px;
}
.search-cnt {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-flow: column;
  padding-top: 130px;
}
.input-cnt {
  position: relative;
  margin: 0px auto;
  margin-bottom: 35px;
}
.search-icon {
  width: 25px;
  height: 27px;
  background: none;
  position: absolute;
  left: 10px;
  top: 9px;
}
.search-input {
  width: 550px;
  letter-spacing: inherit;
  color: currentColor;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 10px 0px 10px 40px;
  font-size: 18px;
}
.search-btn {
  color: #fff;
  background-color: #f00;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  outline: 0;
  border: 0;
  margin: 0;
  font-size: 20px;
  padding: 5px 48px;
  border-radius: 36px;
  border: 1px solid #090b3d;
  cursor: pointer;
}
.search-loading,
.searched-data {
  margin: 30px 0px;
}
.search-result {
  color: #fff;
  font-size: 25px;
}
.search-atrCnt {
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 15px 0px;
  text-align: left;
  border-radius: 5px;
  display: inline-block;
}
.search-alert {
  margin: 30px 0px;
  color: red;
  font-size: 20px;
}
.staking-url {
  position: absolute;
  right: 78px;
  bottom: 55px;
  color: #fff;
}
.collection-link {
  color: #fff;
  font-size: 20px;
}
